@use "theme/variables" as *;

// A float-panel is a component that reserves its own space within a view
// on large enough screens (tablet/desktop), but changes to a floating panel
// on small/mobile displays. The panel collapses are operated by thumb toggles
// in PanelMenu.

.float-panel {

    .toggle {
        // Toggles that may be left from being a panel-right on another
        // breakpoint are replaced by the ones inside nav-sidebar.
        // display: none;
    }

    > header {
        align-items: center;
        background: $default-5;
        border-bottom: 2px solid $default-4;
        color: $default-1;
        display: flex;
        flex-direction: row;
        font-size: $font-l;
        justify-content: space-between;
        padding: 0 $spacer-1;
        padding-right: 0;
        width: 100%;

        &:hover {
            background: $success-5;
            border-bottom: 2px solid $success-4;
            color: $success-1;
        }

        .title {
            align-items: center;
            display: flex;
            height: $spacer-8;
            text-transform: uppercase;

            svg {
                margin-right: $spacer-05;
            }
        }
    }

    &.float-panel--active {
        background: $grey-6;
        border-right: 1px solid $grey-4;
        box-shadow: $shadow-down-d-r;
        display: flex;
        height: var(--app-height);
        left: -1 * $panel-collapsed-width;
        max-width: $panel-width;
        overflow-x: hidden; // no horizontal scrollbar during animating collapse

        // Element needs to scale with maximum viewport width.
        padding-left: $panel-collapsed-width + 4px;
        position: absolute;
        top: 0;
        transition: max-width 0.3s ease, opacity 0.3s ease;
        width: 100%;

        // (!) Above bar-main
        z-index: 100002;


        &.collapsed {
            max-width: 0;
            overflow: hidden;
        }
    }
}

html.tablet {

    .view-container {
        // The view-container is still relatively positioned, so
        // active float-panels within the .view-container must
        // be repositioned to the top of the screen.

        .float-panel {

            &.float-panel--active {
                top: calc(-1 * var(--header-size));
            }
        }
    }
}
